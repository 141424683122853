<template>
  <form class="space-y-8 divide-y divide-gray-200">
    <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      <div>
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{title}}
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            {{subtitle}}
          </p>
        </div>

        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Name
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input v-model="name" type="text" name="first-name" id="name" autocomplete="given-course-name" class="max-w-lg block w-full shadow-sm   sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Percent
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input v-model="percent_off" type="number" name="percent_off" id="percent_off" autocomplete="sec-per-page" class="max-w-lg block w-full shadow-sm   sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>

import {mapMutations, mapGetters, mapState} from 'vuex'
import {RefreshIcon} from '@heroicons/vue/solid'
import questionAdd from "../questions/questionAdd";

export default {
  components: {
    RefreshIcon,
    questionAdd
  },
  methods: {
    ...mapMutations('courses', ['setModalFormData']),
    setFile(event, key) {
      let data = {}
      data[key] = event.target.files[0]
      this.setModalFormData(data)
    }
  },
  computed: {
    ...mapState('courses', ['modalFormData']),
    name: {
      get() {
        return this.modalFormData.name
      },
      set(v) {
        this.setModalFormData({'name': v})
      }
    },
    percent_off: {
      get() {
        return this.modalFormData.percent_off
      },
      set(v) {
        this.setModalFormData({'percent_off': v})
      }
    },
  },
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
  },
}
</script>