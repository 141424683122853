<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <button @click="openNewModal = true" type="button" class="mb-6 inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 ">
      <PlusSmIconSolid class="h-5 w-5" aria-hidden="true" />
    </button>
    <div class="bg-white shadow overflow-hidden sm:rounded-md">
      <ul role="list" class="divide-y divide-gray-200">
        <li v-for="position in coupons" :key="position.id">
          <div class="block hover:bg-gray-50">
            <div class="px-4 py-4 sm:px-6">
              <div class="flex items-center justify-between">
                <p class="text-sm font-medium text-blue-600 truncate">
                  {{ position.name }}
                </p>
                <div class="ml-2 flex-shrink-0 flex">
                  <p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    {{ position.percent_off }}
                  </p>
                </div>
              </div>
              <div class="mt-2 sm:flex sm:justify-between">
                <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 select-text cursor-auto">
                  <p>
                    {{position.id}}
                  </p>
                </div>
                <div class="flex justify-end w-1/3">
<!--                  <div class="cursor-pointer hover:text-blue-500 text-gray-700" @click="editCoupon(position)">-->
<!--                    <div class="flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-bl-lg">-->
<!--                      <PencilIcon class="w-5 h-5" aria-hidden="true" />-->
<!--                      <span class="ml-3">Edit</span>-->
<!--                    </div>-->
<!--                  </div>-->
                  <div class="hover:text-red-500 text-gray-700 cursor-pointer">
                    <button @click="deleteCoupon(position.pk)" class="flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-br-lg">
                      <TrashIcon class="w-5 h-5" aria-hidden="true" />
                      <span class="ml-3">Delete</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <modal :open="openNewModal" submit-text="Save" @close="openNewModal = false" @submit="publish" :loading="loading">
      <coupons-form
          title="Coupon"
          subtitle="Create new coupon"
      />
    </modal>
  </div>
</template>

<script>
import { CalendarIcon, LocationMarkerIcon, UsersIcon } from '@heroicons/vue/solid'
import { PencilIcon, TrashIcon } from '@heroicons/vue/outline'
import modal from "../ui/modal";
import { PlusSmIcon as PlusSmIconSolid } from '@heroicons/vue/solid'
import { PlusSmIcon as PlusSmIconOutline } from '@heroicons/vue/outline'
import CouponsForm from "../courses/couponsForm";
import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
  name: "courses",
  components: {
    CalendarIcon,
    LocationMarkerIcon,
    UsersIcon,
    PencilIcon,
    TrashIcon,
    modal,
    PlusSmIconOutline,
    PlusSmIconSolid,
    CouponsForm
  },
  data() {
    return {
      openNewModal: false,
      openEditModal: false,
      selectedCourse: false,
      errors: [],
      loading: false,
    }
  },
  computed: {
    ...mapGetters('courses', ['coupons'])
  },
  methods: {
    ...mapActions('courses', ['fetchCoupons', 'publishNewCoupon', 'editCouponRequest']),
    ...mapMutations('courses', ['setModalFormData']),
    editCoupon(c) {
      let product = {...c}
      this.setModalFormData(product)
      this.openEditModal = true;
    },
    async publish() {
      this.loading = true
      await this.publishNewCoupon()
      this.openNewModal = false;
      this.loading = false
    },
    async deleteCoupon(c) {
      await this.axios.delete(`user/coupon/${c}/`)
      await this.fetchCoupons()
    }
  },
  async mounted() {
    await this.fetchCoupons()
  },
}
</script>
