<template>
  <div>
    <button @click="openNewModal = true; setModalFormData({courses: []})" type="button" class="mb-6 inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 ">
      <PlusSmIconSolid class="h-5 w-5" aria-hidden="true" />
    </button>
    <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <li v-for="course in $store.state.courses.packages" :key="course.name" class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1 truncate">
            <div class="flex items-center space-x-3">
              <h3 class="text-gray-900 text-sm font-medium truncate">{{ course.product.name }}</h3>
              <span class="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">{{ course.product.prices.find(e=>e.active)?.unit_amount / 100}} EUR</span>
            </div>
<!--            <p class="mt-1 text-gray-500 text-sm truncate">{{ course.description }}</p>-->
          </div>
          <!--          <img class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0" :src="course.imageUrl" alt="" />-->
        </div>
        <div>
          <div class="-mt-px flex divide-x divide-gray-200">
            <button class="w-0 flex-1 flex cursor-pointer hover:text-blue-500 text-gray-700" @click="editCourse(course)">
              <div class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-bl-lg">
                <PencilIcon class="w-5 h-5" aria-hidden="true" />
                <span class="ml-3">Edit</span>
              </div>
            </button>
            <button @click="deleteCourse(course.pk)" class="-ml-px w-0 flex-1 flex hover:text-red-500 text-gray-700 cursor-pointer">
              <div class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm font-medium border border-transparent rounded-br-lg">
                <TrashIcon class="w-5 h-5" aria-hidden="true" />
                <span class="ml-3">Delete</span>
              </div>
            </button>
          </div>
        </div>
      </li>
<!--      <modal :open="true" />-->
      <modal :open="openNewModal" submit-text="Save" @close="openNewModal = false" @submit="publish" :loading="loading">
        <packages-form
            title="Package"
            subtitle="Create new package"
        />
      </modal>
      <modal :open="openEditModal" submit-text="Edit" @close="openEditModal = false; $store.commit('courses/clearModalFormData')" @submit="edit" :loading="loading">
        <packages-form
            title="Package"
            subtitle="Edit package"
        />
      </modal>
    </ul>
    <error-notification :message="error" :show="!!error" />
  </div>
</template>

<script>
import { PencilIcon, TrashIcon } from '@heroicons/vue/outline'
import modal from "../ui/modal";
import { PlusSmIcon as PlusSmIconSolid } from '@heroicons/vue/solid'
import { PlusSmIcon as PlusSmIconOutline } from '@heroicons/vue/outline'
import packagesForm from "@/components/courses/packagesForm";
import {mapActions, mapGetters, mapMutations} from 'vuex'
import errorNotification from "@/components/ui/ErrorNotification";

export default {
  name: "courses",
  components: {
    PencilIcon,
    TrashIcon,
    modal,
    PlusSmIconOutline,
    PlusSmIconSolid,
    packagesForm,
    errorNotification
  },
  data() {
    return {
      openNewModal: false,
      openEditModal: false,
      selectedCourse: false,
      errors: [],
      loading: false,
      error: null
    }
  },
  computed: {
    ...mapGetters('courses', ['courses'])
  },
  methods: {
    ...mapActions('courses', ['fetchPackages', 'publishNewPackage', 'editPackageRequest']),
    ...mapMutations('courses', ['setModalFormData']),
    editCourse(c) {
      let product = {...c}
      product['product_name'] = product.product.name
      product['product_price'] = product.product.prices.find(e=>e.active).unit_amount / 100
      delete product.product
      console.log(product, "KK")
      this.setModalFormData(product)
      this.selectedCourse = c;
      this.openEditModal = true;
    },
    async publish() {
      if (!this.loading) {
        try {
          this.loading = true
          await this.publishNewPackage()
          this.openNewModal = false;
          this.loading = false
        } catch (e) {
          this.loading = false
          let o = '';
          for (const oKey in e.response.data) {
            o += ` Field ${oKey} has errors: ${e.response.data[oKey].join(',')}`
          }
          this.error = o
          this.openNewModal = true;
          setTimeout(
              ()=>{
                this.error = null
              }, 5000
          )
        }
      }
    },
    async edit() {
      if (!this.loading) {
        try {
          this.loading = true
          await this.editPackageRequest()
          this.openEditModal = false;
          this.loading = false
        } catch (e) {
          this.loading = false
          let o = '';
          for (const oKey in e.response.data) {
            o += ` Field ${oKey} has errors: ${e.response.data[oKey].join(',')}`
          }
          this.error = o
          this.openEditModal = true;
          setTimeout(
              ()=>{
                this.error = null
              }, 5000
          )
        }
      }
    },
    async deleteCourse(c) {
      await this.axios.delete(`service/package/${c}/`)
      await this.fetchPackages()
    }
  },
  async mounted() {
    await this.fetchPackages()
  },
}
</script>

<style scoped>

</style>